<div class="py-5" [ngClass]="inverted ? 'bg-blue color-white' : ''">
    <div class="container">
        <div class="row">
            <div *ngFor="let col of columns" class="col-12 col-md-6 col-lg-4 my-5">
                <h2 [ngClass]="inverted ? '' : 'color-blue'" [innerHTML]="col?.heading"></h2>
                <p *ngFor="let p of col?.text" [innerHTML]="p"></p>
                <a *ngIf="col?.link" [routerLink]="col?.link?.routerLink" class="unstyled font-weight-bold">{{col?.link?.text}}</a>
            </div>
        </div>
    </div>
</div>
