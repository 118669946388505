<!-- <maif-header [config]="{
    bgImage: '/assets/images/home/header.jpg',
    h1: 'Malermeister Maifarth',
    h2: 'Qualität aus Meisterhand'
}"></maif-header> -->

<div class="container mt-5">
    <div class="row">
        <div class="col-12 text-center">
            <img src="/assets/logo_original.png" alt="" class="w-100">
        </div>
    </div>
</div>

<maif-heading-and-text [config]="{
    h1: 'Persönlich vor Ort<br>für beste Qualität.<br>Seit über 100 Jahren.',
    subHeading: 'Warum wir',
    text: [
        'Von Kassel bis Treysa reicht der Kundenstamm der Firma Maler Maifarth aus Felsberg mittlerweile. Dies war nicht immer so. In den Anfängen 1871 hatte Heinrich Maifarth neben der Landwirtschaft alle Hände voll zu tun, um die Felsberger Wohnungen und Höfe zu streichen. Nach dem 2. Weltkrieg haben sich die alten Strukturen geändert.',
        'Markus Maifarth, der die fünfte Generation des Traditionsbetriebes einläutet, übt zusätzlich ein ganz spezielles Fach aus: Neben dem Maler- und Lackierermeister ist er geprüfter Restaurator im Malerhandwerk. Diese Zusatzausbildung qualifiziert ihn, in den Zweigen der Denkmalpflege und der Altbausanierung zu arbeiten.'
    ]
}"></maif-heading-and-text>

<!-- <maif-process [steps]="[
    {
        heading: 'Persönliche Beratung',
        text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: '/assets/images/home/conversation.jpg'
    },
    {
        heading: 'Individuelle Lösung',
        text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: '/assets/images/home/gardening.jpg'
    },
    {
        heading: 'Perfektes Ergebnis',
        text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: '/assets/images/shared/contact.jpg'
    }
]"></maif-process> -->

<maif-heading-and-text [inverted]="true" [config]="{
    h1: 'Unser Malerfachbetrieb ist ein starker Partner.',
    subHeading: 'Leistungen'
}"></maif-heading-and-text>

<maif-text-columns [inverted]="true" [columns]="[
    {
        heading: 'Fassadenanstriche',
        text: [
            'Die Fassaden-Gestaltung gehört zu unseren gefragtesten Leistungen. Hierbei ist besonders auf eine hohe Qualität der Ausführung zu achten, um ein sauberes und langanhaltendes Ergebnis zu erhalten.'
        ]
    },
    {
        heading: 'Malern &amp; Tapezieren',
        text: [
            'Die Gestaltung der Innenräume bildet den ästhetischen Abschluss von Neu- und Umbaumaßnahmen. Wie in jedem Projekt legen wir auch hier höchsten Wert auf die Qualität der Ausführung und gehen auf Ihre individuellen Wünsche ein.'
        ]
    },
    {
        heading: 'Restaurierung',
        text: [
            'Als geprüfter Restaurator im Malerhandwerk halten wir auch historische und kirchliche Gebäude in enger Zusammenarbeit mit der zuständigen Denkmalpflege in Stand. Bei Bedarf kommen hier alte Techniken wie das Maserieren oder das Streichen mit Kalk zum Einsatz.'
        ]
    }
]"></maif-text-columns>

<!-- <maif-image-grid [config]="{
    images: [
        '/assets/images/home/grid-1.jpg',
        '/assets/images/home/grid-2.jpg',
        '/assets/images/home/grid-3.jpg',
        '/assets/images/home/grid-4.jpg'
    ],
    tiles: [
        {
            heading: 'Hochwertige Farben',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
        },
        {
            heading: '100% Abdeckung der alten Farbe',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
        },
        {
            heading: 'Individuelle Raumkonzepte',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
        }
    ]
}"></maif-image-grid> -->

<maif-contact></maif-contact>
