import { Component, OnInit, Input } from '@angular/core';

export interface TextColumn {
  heading: string;
  text: string[];
  link?: {
    text: string;
    routerLink: string;
  }
}

@Component({
  selector: 'maif-text-columns',
  templateUrl: './text-columns.component.html',
  styleUrls: ['./text-columns.component.scss']
})
export class TextColumnsComponent implements OnInit {

  @Input()
  columns: TextColumn[];

  @Input()
  inverted = false;

  constructor() { }

  ngOnInit(): void {
  }

}
