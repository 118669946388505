<div class="container py-7">
    <div class="row">
        <div class="col">
            <span class="text-uppercase">Kontakt</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-8">
            <h1 class="color-blue mb-4">Individuelle Raumkonzepte<br>aus Meisterhand.</h1>
            <div class="link">
                <span>Telefon</span>
                <a href="tel:+4956624681">05662 4681</a>
            </div>
            <div class="link">
                <span>E-Mail</span>
                <a href="mailto:maler.maifarth@t-online.de">maler.maifarth@t-online.de</a>
            </div>
            <div class="link">
                <span>Anschrift</span>
                <a href="https://goo.gl/maps/WbmK3zJe8pMGcgqv9">Obertor 14, 34587 Felsberg</a>
            </div>
        </div>
        <!-- <div class="col-12 d-none d-lg-block col-lg-4">
            <img src="/assets/images/shared/contact.jpg" loading="lazy" class="w-100" alt="">
        </div> -->
    </div>
</div>
